<template>
  <div class="page-kxUser">
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="手机号码">
              <a-input v-decorator="['phone']" placeholder="请输入手机号码"></a-input>
            </a-form-item>
            <a-form-item label="角色">
              <a-select
                class="form-control sm"
                v-decorator="['groupCode']"
                :options="typesMap.groupList"
                placeholder="请选择"
                allowClear
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions"></div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :expandIconColumnIndex="1"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="手机号码">
          <a-input
            disabled
            v-decorator="[
              'phone',
              {
                rules: [{ required: true, message: '请输入手机号码' }],
              },
            ]"
            placeholder="请输入手机号码"
          ></a-input>
        </a-form-item>
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'umsAccountName',
              {
                rules: [{ required: true, message: '请输入用户名' }],
              },
            ]"
            placeholder="请输入用户名"
          ></a-input>
        </a-form-item>
        <a-form-item hidden>
          <a-input v-decorator="['umsAccountId']" />
        </a-form-item>
      </a-form>
    </a-modal>
    <PermissionList ref="permissionList"></PermissionList>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { groupList } from '../services/commonTypes'
import PermissionList from './modules/PermissionList'
export default {
  components: {
    PermissionList,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '手机号码',
        width: '20%',
        align: 'center',
        dataIndex: 'phone',
      },
      {
        title: '用户名',
        width: '20%',
        align: 'center',
        dataIndex: 'umsAccountName',
      },
      {
        title: '角色',
        width: '20%',
        align: 'center',
        dataIndex: 'groupCode',
        customRender: (text, row, index) => {
          return <a-tag>{this.translateType(text, 'groupList')}</a-tag>
        },
      },
      {
        title: '操作',
        width: '20%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <a
                class="row-action-btn"
                onClick={() => {
                  this.edit(row)
                }}
              >
                修改
              </a>

              <a
                class="row-action-btn"
                onClick={() => {
                  this.showPermissionList(row)
                }}
              >
                权限详情
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        groupList,
      }
    },
  },

  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/fast-repair/admin/UserGroupMap/page', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const list = res.data.list
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {}
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '修改'
      this.modal.data = row
      this.safeSetFieldsValue(this.modal.form, row)
    },
    async showPermissionList(row) {
      await this.$refs.permissionList.start({ groupCode: row.groupCode, umsAccountId: row.umsAccountId })
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = '/ums/Kx/updateJson'
            await this.$axios.post(
              actionUrl,
              {
                userName: params.umsAccountName,
                accountId: params.umsAccountId,
              },
              {
                postDataType: 'json',
              }
            )
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
  },
  mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
